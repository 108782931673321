import mitt from "mitt";
import Bus from "./Bus";
window.$ = window.jQuery = require("jquery/dist/jquery.min.js");
window._ = require("lodash");
window.Popper = require("popper.js/dist/popper.min.js").default;
try {
    require("bootstrap/dist/js/bootstrap.min.js");
} catch (e) {
    console.log("jquery not found : " + e);
}
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
var token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.log("CSRF token not found");
}

window.Pup = require("sweetalert2");
window.Toast = Pup.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Pup.stopTimer);
        toast.addEventListener("mouseleave", Pup.resumeTimer);
    },
});
window.BasicPromptNotifier = Pup.mixin({
    icon: "info",
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger mr-3",
    },
    buttonsStyling: false,
});
window.Prompter = Pup.mixin({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "question",
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger mr-3",
    },
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: "Yes, do it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
});

window.moment = require("moment");
const searchBar = document.getElementById("search-bar");
const search = document.getElementById("search-mob");
if (search && searchBar) {
    search.addEventListener(
        "click",
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            searchBar.classList.toggle("d-none");
        },
        false
    );
}
window.emitter = window.emitter || new mitt();
window.bus = window.bus || Bus;
